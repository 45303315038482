import CategoryArticle from "../CategoryArticle";
import CollectionsCarousel from "../CollectionsCarousel";
import GlobalCta from "../GlobalCta";
import GuidesCarousel from "../GuidesCarousel"
import InstagramFeed from "../InstagramFeed";
import MostReadArticles from "../MostReadArticles";
import PracticalTools from "../PracticalTools";
import ResourceCarousel from "../ResourceCarousel";
import SocialNetwork from "../SocialNetwork";
import Subscribe from "../Subscribe";

type Block =
  | Gatsby.DatoCmsMostReadArticlesBlockFragment
  | Gatsby.DatoCmsCollectionCarouselFragment
  | Gatsby.DatoCmsResourceCarouselFragment
  | Gatsby.DatoCmsCategoryArticleFragment
  | Gatsby.DatoCmsGlobalCtaBlockFragment
  | Gatsby.DatoCmsPracticalToolsBlockFragment
  | Gatsby.DatoCmsInstagramFeedFragment
  | Gatsby.DatoCmsSocialNetworkFragment
  | Gatsby.DatoCmsSubscribeFragment
  | Gatsby.DatoCmsGuidesCarouselFragment;

type PageBlockProps = {
  pageCtaCard?: Gatsby.DatoCmsCtaCardFragment;
} & Block;

const PageBlock = ({ pageCtaCard, ...block }: PageBlockProps) => {
  switch (block.__typename) {
    case "DatoCmsMostReadArticlesBlock":
      return <MostReadArticles {...block.mostReadArticles!} />;

    case "DatoCmsCollectionCarousel":
      return <CollectionsCarousel />;

    case "DatoCmsGlobalCtaBlock":
      return <GlobalCta {...block.ctaCard!} />;

    case "DatoCmsPracticalToolsBlock":
      return <PracticalTools {...block.tool!} />;

    case "DatoCmsGuidesCarousel":
      return <GuidesCarousel />;

    case "DatoCmsResourceCarousel":
      return <ResourceCarousel />;

    case "DatoCmsCategoryArticle":
      return <CategoryArticle ctaCard={pageCtaCard} />;

    case "DatoCmsSocialNetwork":
      return <SocialNetwork {...block!} />;

    case "DatoCmsSubscribe":
      return <Subscribe {...block!} />;

    // TODO: Enable this block when Instagram
    // using Facebook API is ready
    // case "DatoCmsInstagramFeed":
    //   return <InstagramFeed {...block!} />;

    default: {
      console.warn("UNKNOWN BLOCK TYPE", block);

      return null;
    }
  }
};

export default PageBlock;
