import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  Heading,
  ListItem,
  Text,
  Link as ChakraLink,
  AccordionItemProps,
} from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { CustomIcon, InternalLink } from "src/atoms";
import useOpenCookiesSettings from "src/components/Cookies/useOpenCookiesSettings";

const TabMobile: FC<Gatsby.FooterLinkListFragment & AccordionItemProps> = ({ title, links, ...props }) => {
  const openCookieSettings = useOpenCookiesSettings();

  const dispatchEvent = (name: string) => {
    if (name === "#cookies") {
      openCookieSettings();
    }
  }

  return (
    <AccordionItem
      borderBottom="1px solid white"
      borderTop="none"
      mb="6"
      {...props}
    >
      {({ isExpanded }) => (
        <Fragment>
          <AccordionButton
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            paddingBottom="8"
            paddingTop="0"
            paddingX="0"
            sx={{
              "& + div": {
                marginTop: "0 !important",
                width: "100%",
              },
            }}
          >
            <Heading
              as="h3"
              color="white"
              fontFamily="Mabry"
              fontSize="font-20"
              fontWeight="normal"
              lineHeight="base"
              variant="heading-3"
            >
              {title}
            </Heading>
            
            <CustomIcon
              name="chevronDown"
              sx={{
                path: {
                  fill: "white",
                },
              }}
              transform={`scale(1.25) ${isExpanded ? "rotate(180deg)" : "rotate(0deg)"}`}
              width="20px"
            />
          </AccordionButton>

          <AccordionPanel
            display="flex"
            flexDirection="column"
            gap="6"
            paddingBottom="8"
            paddingTop="0"
            paddingX="0"
          >
            <List spacing="6">
              {links?.map((link) => {
                if (!link) return null;

                const isInternal = link.link?.startsWith("/");
                const isEvent = link.link?.startsWith("#");

                return (
                  <ListItem key={link.id}>
                    <Text
                      _hover={{
                        opacity: .8,
                      }}
                      color="white"
                      fontSize="font-16"
                      lineHeight="short"
                    >
                      <ChakraLink
                        as={isInternal ? InternalLink : undefined}
                        href={link.link!}
                        onClick={isEvent ? (e) => {
                          e.preventDefault()
                          dispatchEvent(link.link!)
                        } : undefined}
                        to={link.link!}
                      >
                        {link.label}
                      </ChakraLink>
                    </Text>
                  </ListItem>
                );
              })}
            </List>  
          </AccordionPanel>
        </Fragment>
      )}
    </AccordionItem>
  );
};

export default TabMobile;
