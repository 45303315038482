import {
  Box,
  Image,
  Text,
  Flex,
  Stack,
  Button,
  Link,
  Container,
  ContainerProps,
} from "@chakra-ui/react";
import { FC } from "react";
import ArrowRight from "src/images/ui/arrow-right.svg";

const GlobalCta: FC<
  Gatsby.DatoCmsGlobalCtaFragment & { isArticle?: boolean, contentContainer: ContainerProps } & ContainerProps
> = ({ cta, isArticle, contentContainer, ...rest }) => {
  if (!cta) return null;

  const { image, imageBackground, brandLogo, copy, ctaLabel, ctaLink } = cta!;

  return (
    <Container
      my={{ base: "40px", md: "70px" }}
      variant="block"
      {...rest}
    >
      <Container
        maxWidth="container.xxl"
        px={{ lg: 0, xl: "56px" }}
        {...contentContainer}
      >
        <Box
          backgroundColor={imageBackground ? "grey.800" : "shine.yellow"}
          backgroundImage={imageBackground ? `url("${image?.url}")` : "none"}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          borderRadius={{ base: "2xl", md: "2.5xl" }}
          overflow="hidden"
        >
          <Box
            background={imageBackground ? "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 60%)" : "none"}
            p={{ base: 8, md: 14 }}
          >
            <Flex
              alignItems="center"
              direction={{ base: "column", md: "row" }}
              gridGap={{ base: 4, md: 14 }}
              maxW={{ base: "initial", md: imageBackground ? "502px" : "full" }}
            >
              {image && !imageBackground ? (
                <Image
                  alt={image.alt || ""}
                  flexShrink={0}
                  h={{ base: "100px", md: "193px" }}
                  objectFit="contain"
                  src={image.url}
                  w="auto"
                />
              ) : null}

              <Stack
                alignItems={{ base: "center", md: "flex-start" }}
                spacing={{ base: 4, md: 6 }}
                w={{ base: "full", md: "max" }}
              >
                {brandLogo && (
                  <Image
                    alt={brandLogo.alt || ""}
                    maxW={{ base: "105px", md: "159px" }}
                    src={brandLogo.url}
                    w="100%"
                  />
                )}

                <Text
                  color={imageBackground ? "white" : "inherit"}
                  fontFamily="Value Serif Pro"
                  fontSize={{ base: "font-22", md: "font-58" }}
                  fontWeight="normal"
                  letterSpacing="-0.02em"
                  lineHeight={{ base: "shorter", md: "60px" }}
                  textAlign={{ base: imageBackground ? "left" : "center", md: "left" }}
                  variant="article"
                >
                  {copy}
                </Text>

                <Button
                  as={Link}
                  href={ctaLink}
                  px="6"
                  rightIcon={
                    imageBackground ? <Image alt="arrow" h="3" src={ArrowRight} /> : null
                  }
                  variant={imageBackground ? "whitePrimary" : "shinePrimary"}
                  w={{ base: "full", md: "max" }}
                >
                  {ctaLabel}
                </Button>

              </Stack>
            </Flex>
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default GlobalCta;
