import { Link, VStack, Image, Text } from "@chakra-ui/react";
import { FC } from "react";

const Card: FC<Gatsby.DatoCmsGlobalResourcesListQuery["datoCmsGlobalResourcesList"]["list"][number]> = ({
  link,
  image,
  title,
  description,
  counter,
}) => {
  return (
    <VStack
      align="flex-start"
      as={Link}
      bg="gray.100"
      borderRadius="2xl"
      href={link}
      p={{ base: 6, md: 8 }}
      spacing="8"
    >
      <Image
        alt={image?.alt}
        borderRadius="2xl"
        fit="cover"
        height={{ base: "100%", md: "233px" }}
        src={image?.url}
        width="full"
      />

      <VStack
        align="flex-start"
        spacing="2"
      >
        <Text
          fontSize="font-22"
          lineHeight="short"
        >
          {title}
        </Text>

        <Text
          fontSize="font-15"
          lineHeight="base"
        >
          {description}
        </Text>
      </VStack>

      <Text
        color="gray.700"
        fontSize="font-15"
        lineHeight="base"
      >
        {counter}
      </Text>
    </VStack>
  );
};

export default Card;
