import { Tag, TagProps } from "@chakra-ui/react";
import { FC } from "react";

const CustomTag: FC<TagProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Tag
      bg="grey.200"
      borderRadius="4xl"
      color="text.secondary"
      fontFamily="body"
      fontSize="font-16"
      fontWeight="normal"
      height="max"
      lineHeight="base"
      minW="max"
      padding="0"
      paddingLeft="2.5"
      paddingRight="2.5"
      textTransform="uppercase"
      {...rest}
      _hover={{
        bg: "grey.700",
        color: "white"
      }}
      transitionDuration="fast"
      transitionProperty="common"
      transitionTimingFunction="ease-out"
    >
      {children}
    </Tag>
  );
};

export default CustomTag;
