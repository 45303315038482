import {
  Box,
  BoxProps,
  Heading,
  Container,
  Button,
  Image,
  HStack,
} from "@chakra-ui/react";
import { FC, useRef, useState, useEffect } from "react";
import { InternalLink } from "src/atoms";
import ArrowIcon from "src/images/ui/arrow-carousel.svg";

const Carousel: FC<
  BoxProps & {
    to?: string;
    label?: string;
    title?: string;
    length: number;
  }
> = ({ to, label, title, children, length, ...rest }) => {
  const [disable, setDisable] = useState(true);
  const [scroll, setScroll] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [inTransition, setInTransition] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const sideScroll = (
    element: HTMLDivElement,
    direction: string,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;

    setInTransition(true);

    const slideTimer = setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }

      scrollAmount += step;

      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
        setInTransition(false);
      }
    }, speed);
  };

  const next = () => {
    if (containerRef.current) {
      const sliderWidth = sliderRef?.current?.clientWidth || 0;
      const cardWidth = sliderWidth / length;

      const container = containerRef.current || null;

      //scrollable max distance in carousel
      const maxScroll = container.scrollWidth - container.clientWidth;

      const distance =
        //check current scroll position if it's beyond current slideIndex position
        container.scrollLeft > cardWidth * slideIndex
          ? //if current scroll is beyond, get remaining distance to make card left align
            cardWidth * (slideIndex + 1) - container?.scrollLeft
          : cardWidth;

      const right =
        container.scrollLeft + distance > maxScroll ? maxScroll : distance;

      sideScroll(container, "right", 10, right, 10);
    }
  };

  const previous = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const sliderWidth = sliderRef?.current?.clientWidth || 0;
      const cardWidth = sliderWidth / length;
      //determine if the current scroll position is enough to stay in current slide index or should be previous index
      const activeSlideIndex =
        container.scrollLeft - cardWidth * slideIndex > 0
          ? slideIndex
          : slideIndex - 1;
      const distance = container.scrollLeft - cardWidth * activeSlideIndex;

      const left = distance;

      sideScroll(container, "left", 10, left, 10);
    }
  };

  const detectSlideIndex = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const maxScroll = container.scrollWidth - container.clientWidth;
      const sliderWidth = sliderRef.current?.clientWidth || 0;
      const cardWidth = sliderWidth / length;
      const currentIndex = Math.round(container.scrollLeft / cardWidth);

      if (!inTransition) {
        setSlideIndex(currentIndex);
      }

      setScroll(container.scrollLeft);

      if (container.scrollLeft >= maxScroll || maxScroll <= 0) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  };

  useEffect(() => {
    detectSlideIndex();
    containerRef.current?.addEventListener("scroll", detectSlideIndex);
    window.addEventListener("resize", detectSlideIndex);
  });

  const scrollButtons = [
    {
      id: "previous",
      previous: true,
      onClick: previous,
      disabled: scroll === 0,
    },
    { id: "next", previous: false, onClick: next, disabled: disable },
  ];

  return (
    <Box {...rest}>
      <Container
        maxWidth="container.xxl"
        overflow="visible"
        px={{ lg: 0, xl: "56px" }}
      >
        <HStack align="center" justify="space-between" w="full">
          {title && (
            <Heading flex={2} variant="heading-2">
              {title}
            </Heading>
          )}

          <HStack spacing="2">
            {to && label && (
              <Button
                as={InternalLink}
                display={{ base: "none", lg: "flex" }}
                to={to}
                variant="secondary"
              >
                {label}
              </Button>
            )}

            {!(scroll === 0 && disable) && (
              <HStack flex={1} h={{ base: 10, md: 14 }} spacing="2">
                {scrollButtons.map(({ previous, onClick, disabled, id }) => {
                  return (
                    <Button
                      _hover={{
                        transform: disabled ? undefined : "scale(1.1)",
                      }}
                      h="full"
                      isDisabled={disabled}
                      key={id}
                      onClick={onClick}
                      variant="reset"
                      w={{ base: 8, md: 12 }}
                    >
                      <Image
                        alt="arrow"
                        cursor="pointer"
                        margin="0"
                        src={ArrowIcon}
                        transform={
                          previous ? "rotate(0deg)" : "rotate(-180deg)"
                        }
                      />
                    </Button>
                  );
                })}
              </HStack>
            )}
          </HStack>
        </HStack>
      </Container>

      <Box
        maxW={{
          base: "max-content",
          "3xl": "container.xxl",
        }}
        mt={{ base: 4, md: 8 }}
        mx="auto"
        overflow="hidden"
        pr={{ base: 0 }}
        w={{
          base: "full",
          md: "calc(100% + 56px)",
          xl: "calc(100% - 112px)",
          content: "calc(1440px - 112px)",
        }}
      >
        <Box
          overflowX="scroll"
          ref={containerRef}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <HStack
            align="stretch"
            minW="max"
            ml={{ base: 4, md: 18, lg: 0 }}
            mr={{ base: 4, md: 6 }}
            ref={sliderRef}
            spacing={{ base: 4, md: 8 }}
          >
            {children}
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
export default Carousel;
