import { Flex, Link, Text } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";
import RatingsGradeStars from "src/components/Core/GradeStars";

type GlobalRating = {
  ratingValue: number;
  ratingTagline: string;
};

const Ratings: FC<Gatsby.DatoCmsFooterFragment> = ({
  globalRating,
  appStoreRating,
  playStoreRating,
  trustpilotRating,
}) => {
  try {
    globalRating = JSON.parse(globalRating) as GlobalRating
    appStoreRating = JSON.parse(appStoreRating)
    playStoreRating = JSON.parse(playStoreRating)
    trustpilotRating = JSON.parse(trustpilotRating)
  } catch (error) {
    return null;
  }

  const ratings = [
    { name: "App Store", icon: "appStore", ...appStoreRating },
    { name: "Google Play", icon: "playStore", ...playStoreRating },
    { name: "Trustpilot", icon: "trustpilot", ...trustpilotRating },
  ].filter(({ rating }) => rating > 0 && rating <= 5);

  return (
    <Flex
      alignItems={{ base: "center", lg: "flex-start" }}
      direction="column"
      gridGap="6"
      justifyContent="flex-start"
      mt="10"
      >
      {globalRating.ratingValue ? (
        <RatingsGradeStars
          mb="2"
          size="24px"
          spacing="2"
          value={globalRating.ratingValue}
        />
      ) : null}

      {ratings.map(({ name, icon, rating, link }, index) => (
        <Link _hover={{ opacity: .8 }} display="block" href={link} key={name} target="_blank">
          <Flex alignItems="center">
            <CustomIcon
              name={icon}
              sx={{
                path: {
                  fill: "white",
                },
              }}
            />

            <Text
              color="white"
              fontSize="16"
              fontWeight="normal"
              lineHeight="short"
              ml="3"
            >
              {rating}&nbsp;/&nbsp;5 sur {name}
            </Text>
          </Flex>
        </Link>
      ))}
    </Flex>
  );
};

export default Ratings;
