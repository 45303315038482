import { HStack, Link, VStack, Text, Image, StackProps, useBreakpointValue } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";

const Highlights: FC<Pick<Gatsby.DatoCmsFooterFragment, "highlights"> & StackProps> = ({
  highlights,
  ...props
}) => {
  if (!highlights) {
    return null;
  }

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <HStack
      alignItems="flex-start"
      borderBottom={{ base: "none", lg: "1px solid white" }}
      borderTop={{ base: "none", lg: "1px solid white" }}
      justifyContent={{ base: "center", lg: "space-between" }}
      mb={{ base: 0, lg: "70px" }}
      mt={{ base: "10", lg: "70px" }}
      py={{ base: 0, lg: "70px" }}
      spacing={{ base: "6", lg: "10" }}
      {...props}
    >
      {highlights.map(({ text, ctaLabel, ctaLink, image }) => {
        const imageHeight = isMobile ? 50 : 68;
        const imageWidth = Math.round(image?.width * imageHeight / image?.height);

        return (
          <HStack
            alignItems={{ base: "center", lg: "flex-start" }}
            flex={{ base: "0", lg: "1" }}
            justifyContent={{ base: "center", lg: "flex-start" }}
            key={text}
            spacing="6"
          >
            <VStack
              alignItems="center"
              as={isMobile ? "a" : "div"}
              h={{ base: "92px", lg: "auto" }}
              href={ctaLink}
              justifyContent="center"
              target="_blank"
              w={{ base: "92px", lg: "auto" }}
            >
              <Image
                alt={image?.alt}
                as={GatsbyImage}
                flex="0"
                image={image.gatsbyImageData}
                minHeight={imageHeight + "px"}
                width={imageWidth + "px"}
              />
            </VStack>
  
            <VStack
              alignItems="flex-start"
              display={{ base: "none", lg: "flex" }}
              flex="1"
              justifyContent="flex-start"
              spacing="4"
            >
              <Text
                color="white"
                fontSize="font-16"
                lineHeight="short"
              >
                {text}
              </Text>
  
              <Link
                _hover={{
                  textDecoration: "underline",
                }}
                color="white"
                fontFamily="Mabry"
                fontSize="font-15"
                href={ctaLink}
                lineHeight="38px"
                textDecoration="underline"
                textUnderlineOffset="8px"
              >
                {ctaLabel} →
              </Link>
            </VStack>
          </HStack>
        )
      })}
    </HStack>
  );
};

export default Highlights;
