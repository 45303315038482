import { StackProps, HStack, Image, Link } from "@chakra-ui/react";
import { FC } from "react";
import AppStoreImage from "src/images/app-store.png";
import GooglePlayImage from "src/images/google-play.png";

const Stores: FC<StackProps> = ({ ...rest }) => {
  return (
    <HStack
      spacing="3"
      {...rest}
    >
      <Link as="a" href="https://apps.apple.com/fr/app/shine-compte-pro-en-ligne/id1159779855" isExternal target="_blank">
        <Image bg="grey.800" height="10" src={AppStoreImage} />
      </Link>

      <Link as="a" href="https://play.google.com/store/apps/details?id=com.shine.app&hl=fr&gl=US" isExternal target="_blank">
        <Image bg="grey.800" height="10" src={GooglePlayImage} />
      </Link>
    </HStack>
  );
};

export default Stores;
