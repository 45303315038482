import { Box, Flex, Container, ContainerProps, Button, Link } from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";
import { FC } from "react";
import Carousel from "src/components/Core/Carousel";

import Card from "./components/Card";

const Resources: FC<ContainerProps> = ({ ...rest }) => {
  const { datoCmsGlobalResourcesList } =
    useStaticQuery<Gatsby.DatoCmsGlobalResourcesListQuery>(
      graphql`
        query DatoCmsGlobalResourcesList {
          datoCmsGlobalResourcesList {
            title
            ctaLabel
            ctaLink
            list {
              title
              image {
                alt
                url
              }
              description
              counter
              link
            }
          }
        }
      `
    );

  if (!datoCmsGlobalResourcesList) return null;

  const { title, list, ctaLabel, ctaLink } = datoCmsGlobalResourcesList;

  return (
    <Container overflow="visible" variant="block" {...rest}>
      <Carousel
        label={title}
        length={list?.length || 0}
        mb={{ base: "40px", md: "70px" }}
        title={title}
      >
        {list?.map((resource) => {
          if (!resource) return null;

          return (
            <Box
              flex="1"
              key={resource.title}
              maxW={{
                base: "214px",
                md: "300px",
              }}
            >
              <Flex boxSizing="border-box" h="full">
                <Card
                  {...resource!}
                  maxW={{
                    base: "214px",
                    md: "300px",
                  }}
                />
              </Flex>
            </Box>
          );
        })}
      </Carousel>

      {ctaLabel && ctaLink ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          mb={{ base: "80px", md: "70px" }}
          mt={{ base: "40px", md: "70px" }}
        >
          <Button
            as={Link}
            href={ctaLink}
            variant="shinePrimary"
          >
            {ctaLabel}
          </Button>
        </Flex>
      ) : null}
    </Container>
  );
};

export default Resources;
