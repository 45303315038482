import { Container, Heading, Grid, VStack, Text, Button, HStack, Image } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC, useEffect } from "react";
import { CustomIcon } from "src/atoms";
import scrollToSection from "src/lib/scrollToSection";

import ArticleCard from "../../Core/ArticleCard";

type ArticleListProps = {
  heroImage: Gatsby.DatoCmsAssetFragment;
  title: string;
  description: string;
  ctaLabel: string;
  ctaLink: string;
  topArticles?: Gatsby.DatoCmsMostReadArticleFragment[];
  recentArticles?: Gatsby.DatoCmsArticleCardFragment[];
};

const ArticleList: FC<ArticleListProps> = ({
  heroImage,
  title,
  description,
  ctaLabel,
  ctaLink,
  topArticles,
  recentArticles: articlesList
}) => {
  if (!topArticles || !articlesList) {
    return null;
  }

  useEffect(() => {
    const hash = window.location.hash;
    
    if (hash) {
      setTimeout(() => scrollToSection(decodeURIComponent(hash).replace("#", ""), undefined, "start"), 500);
    }
  }, []);

  const onCtaClick = (e: React.MouseEvent) => {
    if (ctaLink.startsWith("#")) {
      scrollToSection(decodeURIComponent(ctaLink.replace("#", "")), e);
    } else {
      window.location.href = ctaLink;
    }
  }

  return (
    <Container
      maxWidth="container.xxl"
      mb="70px"
      mt={{ base: "16px", md: "46px" }}
      overflow="visible"
    >

      <Grid
        gap={{ base: "20", md: "10", lg: "0" }}
        gridTemplateColumns={{ base: "1fr", md: "1fr 1fr", lg: "minmax(240px, 1fr) minmax(422px, 422px) minmax(200px, 300px)" }}
        overflow="visible"
        px={{ lg: 0, xl: "56px" }}
      >
        <VStack
          alignItems="flex-start"
          alignSelf="start"
          flexFlow={{ base: "column-reverse", md: "column" }}
          gridArea="1/1"
          gridColumn={{ base: "1/1", md: "1/3", lg: "1/1" }}
          position={{ base: "relative", lg: "sticky" }}
          spacing={{ base: 0, md: "6" }}
          top={{ base: 0, lg: "65px" }}
        >
          <Image
            alt={heroImage.alt}
            as={GatsbyImage}
            backgroundPosition="center 35%"
            backgroundSize="cover"
            borderRadius="2xl"
            image={heroImage.gatsbyImageData}
            mt={{ base: "6", md: 0 }}
            style={{
              aspectRatio: "4/3",
            }}
            w="full"
          />

          <VStack
            alignItems={{ base: "center", md: "flex-start" }}
            spacing={{ base: "6", md: "4" }}
            textAlign={{ base: "center", md: "left" }}
            w="full"
          >
            <Heading
              as="h1"
              fontFamily="Value Serif Pro"
              fontSize={{ base: "font-42", xl: "font-58" }}
              fontWeight="normal"
              letterSpacing="-0.02px"
              lineHeight={{ base: "45px", xl: "60px" }}
              w="full"
            >
              {title}
            </Heading>

            <Text
              fontSize={{ base: "font-14", md: "font-20" }}
              lineHeight={{ base: "shorter", md: "short" }}
            >
              {description}
            </Text>
          </VStack>

          {ctaLabel && ctaLink ? (
            <Button
              as="a"
              display={{ base: "none", md: "flex" }}
              onClick={onCtaClick}
              variant="shinePrimary"
            >
              {ctaLabel}
            </Button>
          ) : null}
        </VStack>

        <VStack
          alignItems="flex-start"
          borderColor="gray.800"
          borderLeft={{ md: "none", lg: "1px solid" }}
          borderRight={{ base: "none", md: "1px solid" }}
          gridArea={{ md: "2/1", lg: "1/2" }}
          ml={{ md: 0, lg: "10" }}
          mr={{ md: 0, lg: "10" }}
          pl={{ md: 0, lg: "10" }}
          pr={{ base: 0, md: "10" }}
          spacing="6"
        >
          <HStack spacing="2">
            <CustomIcon
              name="starOutline"
              sx={{
                width: "28px",
                height: "28px",
              }}
            />

            <Text
              as="h2"
              fontSize="font-28"
              fontWeight="normal"
              lineHeight="38px"
            >
              Top articles
            </Text>
          </HStack>

          {topArticles.map((card) => (
            <ArticleCard
              {...card!}
              key={card.id}
              mb="10"
            />
          ))}

          {ctaLabel && ctaLink ? (
            <Button
              alignSelf="center"
              as="a"
              display={{ base: "flex", md: "none" }}
              mt="40px !important"
              onClick={onCtaClick}
              variant="shinePrimary"
            >
              {ctaLabel}
            </Button>
          ) : null}
        </VStack>

        <VStack
          alignItems="flex-start"
          gridArea={{ base: "3/1", md: "2/2", lg: "1/3" }}
          spacing="6"
        >
          <HStack spacing="2">
            <CustomIcon
              name="timer"
              sx={{
                width: "28px",
                height: "28px",
              }}
            />

            <Text
              as="h2"
              fontSize="font-28"
              fontWeight="normal"
              lineHeight="38px"
            >
              Derniers articles
            </Text>
          </HStack>

          {articlesList.map((card) => (
            <ArticleCard
              {...card!}
              borderBottom="1px solid"
              borderColor="gray.800"
              hideIllustration
              key={card.id}
              mb="6"
              pb={{ base: "6", md: "2" }}
              w="full"
            />
          ))}
        </VStack>
      </Grid>

    </Container>
  );
};

export default ArticleList;
