import { Box, Stack, StackProps } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

import { getStarDecomposition } from "./utils";

const RatingsGradeStar: FC<Partial<any>> = (props) => (
  <CustomIcon left={0} name="star" position="absolute" top={0} {...props} />
);

type RatingsGradeStarsProps =
  StackProps
  & {
    value: number;
    size?: string | number;
    spacing?: StackProps["spacing"];
  };

const RatingsGradeStars: FC<RatingsGradeStarsProps> = ({
  value,
  size = "1em",
  spacing = "space-4",
  ...props
}) => {
  const { STAR_NUMBER, fullStarsCount, lastStarPolygon } = getStarDecomposition(
    value,
  );

  return (
    <Stack direction="row" spacing={spacing} {...props}>
      {Array(STAR_NUMBER)
        .fill("")
        .map((_, i) => (
          <Box boxSize={size} key={i} position="relative">
            {i >= fullStarsCount && (
              <RatingsGradeStar
                sx={{
                  path: {
                    fill: "grey.500",
                  },
                }}
                width={size}
              />
            )}

            {i <= fullStarsCount && (
              <RatingsGradeStar
                css={
                  i === fullStarsCount && {
                    clipPath: lastStarPolygon,
                  }
                }
                sx={{
                  path: {
                    fill: "white",
                  },
                }}
                width={size}
              />
            )}
          </Box>
        ))}
    </Stack>
  );
};

export default RatingsGradeStars;
