import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { Container, Flex, Box, Text, Heading, VStack } from "@chakra-ui/react";
import { FC } from "react";
import DatoCmsHubspotFormIntegration from "src/blocks/DatoCmsHubspotForm/DatoCmsHubspotFormIntegration"

const Subscribe: FC<Gatsby.DatoCmsSubscribeFragment> = ({
  id,
  title,
  description,
  hubspotFormId,
  hubspotInlineMessage,
  hubspotRedirectUrl
}) => {
  return (
    <HubspotProvider>
      <Container
        maxWidth="container.xxl"
        my={{ base: "40px", md: "70px" }}
        px={{ lg: 0, xl: "56px" }}
      >
        <Flex
          alignItems="flex-start"
          bg="shine.yellow"
          borderRadius="2xl"
          flexDirection={{ base: "column", md: "row" }}
          gridGap={{ base: "6", md: "20" }}
          justifyContent="space-between"
          p={{ base: "6", md: "20" }}
        >
          <Box
            flex="1"
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              as="h3"
              fontFamily="Value Serif Pro"
              fontSize={{ base: "font-42", md: "font-58" }}
              letterSpacing="-0.02px"
              lineHeight={{ base: "45px", md: "60px" }}
              mb="6"
            >
              {title}
            </Heading>

            <Text
              fontSize={{ base: "font-15", md: "font-22" }}
              lineHeight={{ base: "tall", md: "short" }}
            >
              {description}
            </Text>
          </Box>

          <VStack
            alignItems="flex-start"
            flex="1"
            spacing="6"
            w="full"
          >
            <DatoCmsHubspotFormIntegration
              bg="transparent"
              borderRadius="none"
              formId={hubspotFormId!}
              inlineMessage={hubspotInlineMessage}
              maxW="full"
              my="0"
              p="0"
              recordId={id}
              redirectUrl={hubspotRedirectUrl}
              w="full"
            />
          </VStack>
        </Flex>
      </Container>
    </HubspotProvider>
  );
};

export default Subscribe;
