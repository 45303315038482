import { Grid, Flex, Box } from "@chakra-ui/react";
import { ReactNode, FC } from "react";
import { Helmet } from "react-helmet";
import breakpoints from "src/@chakra-ui/gatsby-plugin/foundations/breakpoints";
import { getHasSummary } from "src/services/article/summary";

import Summary from "../Article/Summary/desktop";

import Footer from "./Footer";
import Header from "./Header";
import { HEADER_HEIGHT_MOBILE, HEADER_HEIGHT_DESKTOP } from "./Header/Header";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Layout: FC<{
  children: ReactNode;
  article?: Gatsby.ArticleDataFragment["body"];
  activeSection?: string;
  noIndex?: boolean;
  footerLinksBreakpoint?: keyof typeof breakpoints;
}> = (props) => {
  const { children, article, activeSection, noIndex, footerLinksBreakpoint } =
    props;

  const hasSummary = article && getHasSummary(article!);

  return (
    <div>
      {noIndex === true && (
        <Helmet>
          <meta content="noindex" name="robots" />
        </Helmet>
      )}

      <Header />

      <Grid
        gap="0"
        marginLeft="auto"
        marginRight="auto"
        maxW="container.max"
        mt={{
          base: `${HEADER_HEIGHT_MOBILE}px`,
          lg: `${HEADER_HEIGHT_DESKTOP}px`,
        }}
        sx={{
          "*": {
            "::selection": {
              background: "shine.yellow",
            },
          },
        }}
        templateColumns={{
          base: "1fr",
          xl: article && hasSummary ? "1fr auto" : "1fr",
        }}
      >
        <Flex
          flexDirection="column"
          minHeight={{
            base: `calc(100vh - ${HEADER_HEIGHT_MOBILE}px)`,
            lg: `calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`,
          }}
          minW="full"
          paddingX={{ base: 0, lg: 14, "xl": hasSummary ? "95px" : 0 }}
          pt="6"
        >
          <Box as="main" flexGrow={1}>
            {children}
          </Box>

        </Flex>

        {article && hasSummary ? (
          <Box display={{ base: "none", xl: "block" }}>
            <Summary activeSection={activeSection} data={article} />
          </Box>
        ) : null}
      </Grid>

      <Footer linksBreakpoint={footerLinksBreakpoint} />
    </div>
  );
};

export default Layout;
