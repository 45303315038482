import {
  Text,
  Box,
  Grid,
  Container,
  BoxProps,
  Image,
  Link,
  Flex,
  HStack,
  useBreakpointValue,
  Accordion,
} from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import * as DOMPurify from 'isomorphic-dompurify';
import { FC, Fragment } from "react";
import breakpoints from "src/@chakra-ui/gatsby-plugin/foundations/breakpoints";
import { InternalLink } from "src/atoms";
import ShineLogo from "src/images/shine-white.svg";

import Highlights from "./parts/Highlights";
import Ratings from "./parts/Ratings";
import SocialMedia from "./parts/SocialMedia";
import Stores from "./parts/Stores";
import TabDesktop from "./parts/TabDesktop";
import TabMobile from "./parts/TabMobile";

type FooterProps = BoxProps & {
  linksBreakpoint?: keyof typeof breakpoints;
};

const Footer: FC<FooterProps> = ({
  linksBreakpoint = "xl",
  ...rest
}) => {
  const { footer } = useStaticQuery<Gatsby.FooterQuery>(
    graphql`
      query Footer {
        footer: datoCmsFooter {
          ...DatoCmsFooter
        }
      }
    `
  );

  const { footerLinks, highlights, disclaimerNode } = footer!;

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const canSanitize = DOMPurify.isSupported;
  const disclaimerUnsanitized = disclaimerNode?.childMarkdownRemark?.html || '';
  const disclaimerPlainText = disclaimerNode?.childMarkdownRemark?.excerpt || '';
  const disclaimerSanitized = DOMPurify.sanitize(disclaimerUnsanitized, { USE_PROFILES: { html: true } });

  const legalLinks = {
    id: 'legal-links',
    title: 'Legal Links',
    links: [
      {
        label: `Conditions d’utilisation`,
        link: 'https://www.shine.fr/lp/cgu-ep-en-clair/',
      },
      {
        label: `Mentions légales`,
        link: 'https://www.shine.fr/mentions-legales/',
      },
    ],
  }

  return (
    <Box
      as="footer"
      bg="grey.800"
      mt={{ base: "40px", md: "70px" }}
      {...rest}
    >
      <Container
        maxWidth="container.xxl"
        pl={{ base: 6, lg: 0, xl: "56px" }}
        pr={{ base: 6, lg: 0, xl: "56px" }}
      >
        <Box
          pb={{ base: "70px", lg: 30 }}
          pt={{ base: "70px", lg: 30 }}
          px={{ base: 0, md: 0, lg: 14, xl: 0 }}
        >
          <Flex
            direction={{ base: "column", lg: "row" }}
            gridGap={{ base: "70px", lg: 20, content: 40 }}
            justifyContent="space-between"
          >
            <Flex
              alignItems={{ base: "center", lg: "flex-start" }}
              direction="column"
              justifyContent="flex-start"
            >
              <Image height="6" src={ShineLogo} />

              <Ratings {...footer!} />

              <Stores
                display={{ base: "flex", lg: "none" }}
                mt="70px"
              />

              <Highlights
                display={{ base: "flex", lg: "none" }}
                highlights={highlights}
              />
            </Flex>

            <Box>
              {isMobile ? (
                <Accordion
                  allowToggle
                  w="100%"
                >
                  {footerLinks?.map((tab) => {
                    return (
                      <Fragment key={tab?.id}>
                        <TabMobile {...tab!} />
                      </Fragment>
                    );
                  })}
                </Accordion>                
              ) : (
                <Grid
                  gap="10"
                  gridTemplateColumns={{
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                    [linksBreakpoint]: "repeat(4, 1fr)",
                  }}
                  w="100%"
                >
                  {footerLinks?.map((tab) => {
                    return (
                      <Fragment key={tab?.id}>
                        <TabDesktop {...tab!} />
                      </Fragment>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </Flex>

          <HStack
            justifyContent={{ base: "center", lg: "space-between" }}
            my="70px"
            spacing="0"
          >
            <Stores
              display={{ base: "none", lg: "flex" }}
            />

            <SocialMedia {...footer!} />
          </HStack>

          <Highlights
            display={{ base: "none", lg: "flex" }}
            highlights={highlights}
          />

          <Box mt={{ base: "8", lg: "10" }}>
            {canSanitize ? <Box
              color="grey.400"
              dangerouslySetInnerHTML={{ __html: disclaimerSanitized }}
              fontFamily="Mabry"
              fontSize="font-16"
              letterSpacing="04"
              lineHeight="short"
              sx={{
                a: {
                  textDecoration: "underline",
                },
              }}
            /> : <Text
              color="grey.400"
              fontFamily="Mabry"
              fontSize="font-16"
              letterSpacing="04"
              lineHeight="short"
              sx={{
                a: {
                  textDecoration: "underline",
                },
              }}
            >
              {disclaimerPlainText}
              </Text>
            }
          </Box>

          <Flex
            alignItems={{ base: "flex-start", lg: "center" }}
            flexWrap="wrap"
            gridGap={{ base: "2", lg: "6" }}
            justifyContent={{ base: "flex-start", lg: "center" }}
            mt="10"
          >
            {legalLinks.links.map((link) => {
              if (!link) return null;

              const isInternal = link.link?.startsWith("/");

              return (
                <Text
                  _hover={{
                    opacity: .8,
                  }}
                  color="grey.400"
                  fontSize="font-16"
                  key={link.label}
                  lineHeight="short"
                  textAlign="center"
                >
                  <Link
                    as={isInternal ? InternalLink : undefined}
                    href={link.link!}
                    to={link.link!}
                  >
                    {link.label}
                  </Link>
                </Text>
              )
            })}
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
