import {
  Image,
  Text,
  Grid,
  Flex,
  Box,
  Heading,
  LinkBoxProps,
  LinkBox,
  LinkOverlay,
  chakra,
  TextProps,
} from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { InternalLink } from "src/atoms";
import { getCategoryLevel } from "src/services/article/category";
import { getFormattedDates } from "src/services/article/date";
import { getArticleLink } from "src/services/article/link";
import { getReadTime } from "src/services/article/readTime";
import { getFormattedTitle } from "src/services/article/title";
import { getMultiDepthCategoryLink } from "src/services/categories/link";
import { variableTransformer } from "src/services/metadata/variable";

import FormattedDates from "./FormattedDates";

const ArticleCard: FC<Gatsby.DatoCmsArticleCardFragment & LinkBoxProps & { hideIllustration?: boolean }> = ({
  illustration,
  title,
  body,
  slug,
  date,
  primaryCategory,
  meta,
  hideIllustration = false,
  ...rest
}) => {
  const readTime = getReadTime(toPlainText(body) || "", true);

  const { firstPublishedAt, updatedAt } = getFormattedDates(date!, meta);

  const upperCategories = primaryCategory
    ? getCategoryLevel(primaryCategory)
    : [];

  const formattedTitle = getFormattedTitle(variableTransformer(title!) || "");

  const getCategory = (props: TextProps) => {
    if (!primaryCategory) return null;

    return (
      <Text
        fontSize="font-15"
        lineHeight="base"
        {...props}
      >
        <InternalLink
          to={getMultiDepthCategoryLink(
            primaryCategory.slug!,
            upperCategories.slice(0, upperCategories.length - 1)
          )}
        >
          {primaryCategory.title}
        </InternalLink>

        {readTime ? <chakra.span color="text.primary" ml={2} textTransform="none">
            - {readTime}
        </chakra.span> : null}
      </Text>
    )
  }

  return (
    <LinkBox {...rest} role="group">
      <Grid
        direction={{ base: "row", md: "column" }}
        gap="4"
        minH="100%"
        templateColumns={{ base: hideIllustration ? "1fr" : "138px 1fr", md: "1fr" }}
        templateRows={{ md: "auto 1fr" }}
      >
        {illustration?.thumbnail && !hideIllustration && (
          <Box
            borderRadius="2xl"
            height={{ base: "92px", md: "173px" }}
            overflow="hidden"
            transform="translateZ(0)"
            width={{ base: "138px", md: "unset" }}
          >
            <Image
              _groupHover={{
                transform: "scale(1.03)",
              }}
              alt={illustration?.alt || ""}
              as={GatsbyImage}
              fit="cover"
              h="100%"
              image={illustration?.thumbnail}
              imgStyle={{
                width: "100%",
                height: "100%",
                transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1)",
              }}
              transitionDuration="normal"
              transitionProperty="common"
              transitionTimingFunction="ease-out"
              w="100%"
            />
          </Box>
        )}

        <Flex
          alignContent="flex-start"
          display={{ base: "block", md: "flex" }}
          flex={{ md: 1 }}
          flexWrap="wrap"
        >
          {getCategory({ display: { base: "none", md: "block" } })}

          <LinkOverlay
            as={InternalLink}
            h="max"
            minW="full"
            to={getArticleLink(slug || "")}
          >
            <Heading
              _groupHover={{
                textDecoration: "underline",
                textUnderlineOffset: "4px",
                textDecorationThickness: "2px",
              }}
              as="h3"
              fontSize="font-20"
              fontWeight="normal"
              lineHeight="short"
              mb={{ base: "1", md: "2" }}
              mt={{ base: "0", md: "2" }}
              variant="heading-3"
            >
              {formattedTitle}
            </Heading>
          </LinkOverlay>

          <Grid
            alignItems="center"
            display={{ base: "grid", md: "block" }}
            gap="1"
            gridTemplateColumns="1fr auto"
          >
            {getCategory({
              display: { base: "block", md: "none" },
              textTransform: "uppercase",
              fontSize: "font-11",
              lineHeight: "shorter",
            })}

            <FormattedDates
              firstPublishedAt={firstPublishedAt}
              fontSize={{ base: "font-11", md: "font-15" }}
              iconScale={.8}
              lineHeight="15px"
              spacing={{ base: 1, md: "10px" }}
              updatedAt={updatedAt}
            />
          </Grid>

        </Flex>
      </Grid>
    </LinkBox>
  );
};

export default ArticleCard;
