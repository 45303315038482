import { VStack, Heading, HStack, Link, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

const SocialNetwork: FC<Gatsby.DatoCmsSocialNetworkFragment> = ({ title, networks }) => {
  return (
    <VStack
      my={{ base: "80px", md: 0 }}
      py={{ base: 0, md: "70px" }}
      spacing="10"
    >
      <Heading variant="heading-2">
        {title}
      </Heading>

      <HStack spacing={{ base: "10", md: "20" }}>
        {networks?.map((network) => {
          if (!network) return null;

          return (
            <Link href={network.url} isExternal key={network.name}>
              <Flex
                alignItems="center"
                border="1px solid"
                borderColor="white"
                borderRadius="full"
                h="10"
                justifyContent="center"
                minH="10"
                minW="10"
                w="10"
              >
                <CustomIcon
                  name={network.name}
                  sx={{
                    path: {
                      fill: "text.primary",
                    },
                  }}
                  transform="scale(2.05)"
                />
              </Flex>
            </Link>
          );
        })}
      </HStack>
    </VStack>
  );
};

export default SocialNetwork;
