import { Box, Flex, Container, ContainerProps, Button, Link, Heading, useBreakpointValue } from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";
import { FC, useMemo, useState } from "react";
import Slider from "react-slick";
import slugify from "slugify";
import { CustomTag } from "src/atoms";
import CustomArrow from "src/atoms/Buttons/CustomArow";

import ArticleCard from "../../Core/ArticleCard";
import ArticleGrid from "../../Core/ArticleGrid";
import CtaCard from "../../Core/ArticleGrid/CtaCard";

type CategoryArticleProps = {
  ctaCard?: Gatsby.DatoCmsCtaCardFragment;
} & ContainerProps;

const CategoryArticle: FC<CategoryArticleProps> = ({ ctaCard, ...rest }) => {
  const { datoCmsGlobalCategoriesArticlesList, allDatoCmsArticle } =
    useStaticQuery<Gatsby.DatoCmsGlobalCategoriesArticlesListQuery>(
      graphql`
        query DatoCmsGlobalCategoriesArticlesList {
          datoCmsGlobalCategoriesArticlesList {
            title
            categories {
              ...ArticleCategoryFirstLevel
              ...ArticleCategorySecondLevel
              ...ArticleCategoryThirdLevel
              ...ArticleCategoryFourthLevel
              ...ArticleCategoryFifthLevel
              ...ArticleCategorySixthLevel
              ...ArticleCategorySeventhLevel
              ...ArticleCategoryEighthLevel
              ...ArticleCategoryNinthLevel
              ...ArticleCategoryTenthLevel
            }
            ctaLabel
          }
          # TODO: Find a way to limit to 7 articles per category directly in the query
          allDatoCmsArticle(sort: { fields: meta___firstPublishedAt, order: DESC }) {
            articles: nodes {
              ...DatoCmsArticleCard
              categories {
                ...ArticleCategoryFirstLevel
                ...ArticleCategorySecondLevel
                ...ArticleCategoryThirdLevel
                ...ArticleCategoryFourthLevel
                ...ArticleCategoryFifthLevel
                ...ArticleCategorySixthLevel
                ...ArticleCategorySeventhLevel
                ...ArticleCategoryEighthLevel
                ...ArticleCategoryNinthLevel
                ...ArticleCategoryTenthLevel
              }
            }
          }
        }
      `
    );

  if (!datoCmsGlobalCategoriesArticlesList) return null;

  const { title, categories, ctaLabel } = datoCmsGlobalCategoriesArticlesList;

  const { articles } = allDatoCmsArticle;

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const selectedCategoryId = categories?.[selectedCategoryIndex]?.id;
  const selectedCategorySlug = categories?.[selectedCategoryIndex]?.slug;
  
  const lists = useMemo(() => {
    return categories?.map((category) => {
      const list = articles
        ?.filter((article) => article.primaryCategory?.id === category.id || article.categories?.some((c) => c?.id === category.id))
        .slice(0, 7);

      // We inject the CTA card in 2 different positions because on Desktop the CTA is the last element,
      // whereas on Mobile it's in the middle of the list.
      if (ctaCard) {
        list?.splice(3, 0, ctaCard!);
        list?.splice(8, 0, ctaCard!);
      }

      return ({
        id: category.id,
        list,
      })
    }) ?? []
  }, [categories])

  const isMobile = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const [slideIndex, setSlideIndex] = useState(0);
  const settings = {
    variableWidth: true,
    infinite: false,
    prevArrow: <CustomArrow direction="previous" visible={slideIndex !== 0} />,
    nextArrow: (
      <CustomArrow
        direction="next"
        visible={slideIndex !== (categories?.length ?? 0) - 1}
      />
    ),
    beforeChange: (current: number, next: number) => setSlideIndex(next),
  };

  return (
    <Container
      id={slugify(title, { lower: true })}
      maxWidth="container.xxl"
      my={{ base: 0, md: "70px" }}
      px={{ lg: 0, xl: "56px" }}
      {...rest}
    >
      <Heading variant="heading-2">
        {title}
      </Heading>

      <Box
        alignItems="center"
        as={isMobile || isTablet ? Slider : Flex}
        flexWrap={{ base: "nowrap", lg: "wrap" }}
        {...settings}
        justifyContent="center"
        mb="6"
        mt={{ base: "6", lg: "10" }}
        position="relative"
        sx={{
          ".slick-slide": { mx: 1 },
          ".slick-list": { mx: 4, overflow: "visible" },
        }}
      >
        {categories.map((category, index) => {
          const isSelected = index === selectedCategoryIndex;

          return (
            <CustomTag
              bg={isSelected ? "grey.800" : "grey.100"}
              color={isSelected ? "white" : "text.secondary"}
              cursor="pointer"
              display="block !important"
              fontSize={{ base: "font-12", lg: "font-16" }}
              key={category.name ?? category.title}
              mb={{ base: 0, lg: 4 }}
              mr="4"
              onClick={() => setSelectedCategoryIndex(index)}
              paddingLeft="6"
              paddingRight="6"
              paddingY="2"
              textTransform="none"
              w="auto !important"
            >
              {category.name ?? category.title}
            </CustomTag>
          );
        })}
      </Box>

      <Box position="relative">
        {lists.map(({ id, list }) => (
          <ArticleGrid
            key={id}
            position={selectedCategoryId === id ? "relative" : "absolute"}
            visibility={selectedCategoryId === id ? "visible" : "hidden"}
          >
            {list.map((card, index) => {
              if (card.__typename === "DatoCmsArticle") {
                const visibleSm = index <= 6;
                const visibleMd = index <= 5;

                return (
                  <ArticleCard
                    {...card!}
                    display={{
                      base: visibleSm ? "block" : "none",
                      md: visibleMd ? "block" : "none",
                      xl: "block",
                    }}
                    key={card.id}
                  />
                );
              } else {
                const isVisible = (isMobile && index === 3) ? true : !isMobile && index > 3;

                return (
                  <CtaCard
                    {...card}
                    display={isVisible ? "flex" : "none"}
                    key={card.id}
                  />
                );
              }
            })}
          </ArticleGrid>
        ))}
      </Box>

      {ctaLabel && selectedCategorySlug ? (
        <Flex
          alignItems="center"
          justifyContent="center"
          my={{ base: "40px", md: "70px" }}
        >
          <Button
            as={Link}
            href={`/blog/${selectedCategorySlug}/`}
            variant="shinePrimary"
          >
            {ctaLabel}
          </Button>
        </Flex>
      ) : null}
    </Container>
  );
};

export default CategoryArticle;
