import { Flex, Link } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

const SocialMedia: FC<Gatsby.DatoCmsFooterFragment> = ({ socialMedias }) => {
  return (
    <Flex
      alignItems="center"
      direction="row"
      gridGap="6"
      justifyContent="center"
      mt={{ base: 8, lg: 20 }}
      >
      {socialMedias?.map((platform) => {
        const { name, url } = platform!;

        return (
          <Link
            _hover={{
              opacity: .8,
            }}
            href={url}
            isExternal
            key={url}
          >
            <Flex
              alignItems="center"
              border="1px solid"
              borderColor="white"
              borderRadius="full"
              h="10"
              justifyContent="center"
              minH="10"
              minW="10"
              w="10"
            >
              <CustomIcon
                name={name}
                sx={{
                  path: {
                    fill: "white",
                  },
                }}
                transform="scale(0.9)"
              />
            </Flex>
          </Link>
        );
      })}
    </Flex>
  );
};

export default SocialMedia;
