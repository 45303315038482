import { Text, Box, HStack, Image, Flex, Link, Button } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { FC, useState, Fragment } from "react";
import { CustomIcon, InternalLink, SearchInput } from "src/atoms";

import Dropdown from "./components/Dropdown";
import MobileDrawer from "./components/MobileDrawer";
import MobileSearch from "./components/MobileSearch";
import useHeaderDropdownState from "./state";
import { MenuContext } from "./state/context";

export const HEADER_HEIGHT_MOBILE = 65
export const HEADER_HEIGHT_DESKTOP = 64

const Header = () => {
  const { header } = useStaticQuery<Gatsby.HeaderQuery>(
    graphql`
      query Header {
        header: datoCmsHeader {
          ...DatoCmsHeader
        }
      }
    `
  );

  if (!header) {
    return null;
  }

  const { logo, navigation, companyLabel } = header

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const { state, dispatch } = useHeaderDropdownState();

  return (
    <Fragment>
      <Box
        maxW="100vw"
        pointerEvents="none"
        position="fixed"
        top="0"
        w="full"
        zIndex="10"
      >
        <Box
          background="white"
          pointerEvents="auto"
        >
          <Box
            as="header"
            borderBottom={{ base: "1px solid", lg: "none" }}
            borderColor="grey.800"
            margin="auto"
            maxW="container.xxl"
            padding={{
              base: "16px",
              md: "16px 72px",
              lg: "8px 56px",
            }}
            w="100%"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <HStack
                alignItems="center"
                as={InternalLink}
                flexShrink="0"
                spacing={{ base: "2", md: "4"}}
                to={"/"}
              >
                <Image
                  alt={logo?.alt || ""}
                  maxH={{ base: "16px", md: "24px" }}
                  src={logo?.url}
                />

                <Text
                  color="grey.800"
                  fontFamily="Value Serif Pro"
                  fontSize={{ base : "font-14", md: "font-20"}}
                  lineHeight="none"
                >
                  Blog
                </Text>
              </HStack>

              <MenuContext.Provider value={{ state, dispatch }}>
                <HStack
                  display={{ base: "none", lg: "flex" }}
                  flexGrow="1"
                  ml="7"
                  spacing="0"
                >
                  {navigation?.map((dropdown, index) => {
                    if (!dropdown) {
                      return null;
                    }

                    if (!dropdown.sections?.length) {
                      return (
                        <Link
                          as={dropdown.ctaLink?.startsWith("http") ? "a" : InternalLink}
                          fontFamily="Mabry"
                          fontSize="font-15"
                          fontWeight="normal"
                          href={dropdown.ctaLink}
                          key={dropdown.title}
                          px="3"
                        >
                          {dropdown.title}
                        </Link>
                      )
                    }

                    return (
                      <Dropdown
                        data={dropdown}
                        key={dropdown.title}
                        menuIndex={index}
                      />
                    )
                  })}
                </HStack>
              </MenuContext.Provider>

              <Flex
                alignItems="center"
                alignSelf={{ md: "flex-end" }}
                flexShrink="0"
                justifyContent={{ base: "space-between", md: "flex-end" }}
              >
                <SearchInput
                  buttonLeft
                  buttonProps={{
                    top: "calc(100% - 48px)"
                  }}
                  display={{ base: "none", xl: "block" }}
                  inputProps={{
                    h: "48px",
                  }}
                  maxW="155px"
                  mx="5"
                  placeholder="Rechercher"
                />

                <Box
                  _active={{ bg: "transparent" }}
                  _hover={{ bg: "transparent" }}
                  alignItems="center"
                  as="button"
                  bg="transparent"
                  display={{ base: "flex", xl: "none" }}
                  justifyContent="center"
                  onClick={() => setSearchOpen(true)}
                  pr="4"
                  py="6px"
                >
                  <CustomIcon cursor="pointer" name="magnifier"/>
                </Box>

                <Button
                  as={Link}
                  fontSize={{ base: "font-14", lg: "font-16" }}
                  href={process.env.GATSBY_SHINE_URL}
                  isExternal
                  lineHeight={{ base: "none", lg: "base" }}
                  px={{ base: "3", lg: "6" }}
                  py={{ base: "1.5", lg: "3" }}
                  variant="yellow"
                >
                  {companyLabel}

                  <CustomIcon
                    display={{ base: "none", md: "block" }}
                    ml="2"
                    name="arrowRight"
                    transform="scale(1.2)"
                  />
                </Button>

                <Box
                  _active={{ bg: "transparent" }}
                  _hover={{ bg: "transparent" }}
                  alignItems="center"
                  as="button"
                  bg="transparent"
                  display={{ base: "flex", lg: "none" }}
                  justifyContent="center"
                  onClick={() => setMobileMenuOpen(true)}
                  pl="4"
                  py="6px"
                >
                  <CustomIcon cursor="pointer" name="burger" />
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>

      <MobileDrawer
        header={header}
        isOpen={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      />

      <MobileSearch isOpen={searchOpen} onClose={() => setSearchOpen(false)} />
    </Fragment>
  );
};

export default Header;
