import {
  Box,
  List,
  Heading,
  ListItem,
  Text,
  Link as ChakraLink,
  BoxProps,
} from "@chakra-ui/react";
import { FC } from "react";
import { InternalLink } from "src/atoms";
import useOpenCookiesSettings from "src/components/Cookies/useOpenCookiesSettings";

const TabDesktop: FC<Gatsby.FooterLinkListFragment & BoxProps> = ({ title, links, ...props }) => {
  const openCookieSettings = useOpenCookiesSettings();

  const dispatchEvent = (name: string) => {
    if (name === "#cookies") {
      openCookieSettings();
    }
  }

  return (
    <Box {...props}>
      <Heading
        as="h3"
        color="white"
        fontFamily="Mabry"
        fontSize="font-20"
        fontWeight="normal"
        lineHeight="base"
        mb="8"
        variant="heading-3"
      >
        {title}
      </Heading>

      <List spacing="6">
        {links?.map((link) => {
          if (!link) return null;

          const isInternal = link.link?.startsWith("/");
          const isEvent = link.link?.startsWith("#");

          return (
            <ListItem key={link.id}>
              <Text
                _hover={{
                  opacity: .8,
                }}
                color="white"
                fontSize="font-16"
                lineHeight="short"
              >
                <ChakraLink
                  as={isInternal ? InternalLink : undefined}
                  href={link.link!}
                  onClick={isEvent ? (e) => {
                    e.preventDefault()
                    dispatchEvent(link.link!)
                  } : undefined}
                  to={link.link!}
                >
                  {link.label}
                </ChakraLink>
              </Text>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default TabDesktop;
