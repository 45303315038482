function getFormattedTitle(title: string): string {
  let formattedTitle = title

  if (title.length > 80) {
    formattedTitle = title.slice(0, 77) + "..."
  }

 return formattedTitle
}

export { getFormattedTitle };
