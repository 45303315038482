import { graphql, PageProps } from "gatsby";
import { FC } from "react";
import Seo from "src/components/Core/Seo";
import ArticleList from "src/components/Homepage/ArticleList";

import PageBlock from "../components/Core/PageBlock";
import Layout from "../components/Layout";

const Index: FC<PageProps<Gatsby.HomepageQuery>> = ({
  data: {
    latestArticles: { latestArticles },
    page,
  },
}) => {
  const { blocks, ctaCard, heroImage, seo, hotjar, title, description, ctaLabel, ctaLink } = page!;

  const mostReadArticlesBlock = blocks?.find((block) => block?.__typename === "DatoCmsMostReadArticlesBlock");
  const otherBlocks = blocks?.filter((block) => block?.__typename !== "DatoCmsMostReadArticlesBlock");

  return (
    <Layout>
      <Seo hotjar={hotjar} path="/" seo={seo!} />

      <ArticleList
        ctaLabel={ctaLabel!}
        ctaLink={ctaLink!}
        description={description!}
        heroImage={heroImage}
        recentArticles={[...latestArticles!]}
        title={title!}
        topArticles={[...(mostReadArticlesBlock?.mostReadArticles?.articles ?? [])]}
      />

      {otherBlocks?.map((block) => {
        return (
          <PageBlock
            key={block?.id}
            pageCtaCard={ctaCard}
            {...block!}
          />
        );
      })}
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query Homepage {
    page: datoCmsHomePage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      hotjar
      heroImage {
        alt
        gatsbyImageData(width: 1920, placeholder: NONE)
      }
      title
      description
      ctaLabel
      ctaLink
      ctaCard {
        ...DatoCmsCtaCard
      }
      blocks {
        ...DatoCmsMostReadArticlesBlock
        ...DatoCmsResourceCarousel
        ...DatoCmsCategoryArticle
        ...DatoCmsGlobalCtaBlock
        ...DatoCmsInstagramFeed
        ...DatoCmsSocialNetwork
        ...DatoCmsSubscribe
        ...DatoCmsCollectionCarousel
      }
    }
    latestArticles: allDatoCmsArticle(limit: 6, sort: { fields: meta___firstPublishedAt, order: DESC }) {
      latestArticles: nodes {
        ...DatoCmsArticleCard
      }
    }
  }
`;
