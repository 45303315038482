import { Flex, Link, PlacementWithLogical, Tooltip } from '@chakra-ui/react';
import { FC, Fragment, useEffect, useState } from 'react'
import { CustomIcon } from 'src/atoms';

type SocialShareProps = {
  tooltipPlacement?: PlacementWithLogical;
};

const SocialShare: FC<SocialShareProps> = ({ tooltipPlacement = "left" }) => {
  const [shoudShowTooltip, setShouldShowTooltip] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUrl(window.location.href);
    }
  }, []);

  const socialShare = [
    {
      icon: <CustomIcon name="twitter" />,
      href: `https://twitter.com/intent/tweet/?url=${encodeURIComponent(url)}`,
    },
    {
      icon: <CustomIcon name="linkedin" />,
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`,
    },
    {
      icon: <CustomIcon name="facebook" />,
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    },
    {
      icon: <CustomIcon name="instagram" />,
      href: `https://www.instagram.com/shine`,
    },
  ];

  const copyUrl = (e: React.MouseEvent) => {
    e.preventDefault();

    // I test clipboard because it's only available in secure context
    navigator.clipboard?.writeText(url || '')
      .then(() => {
        setShouldShowTooltip(true);
        setTimeout(() => setShouldShowTooltip(false), 2000);
      });
  };

  return (
    <Fragment>
      {socialShare.map(({ icon, href }) => (
          <Link href={href} isExternal key={href}>
            <Flex
              _hover={{ xl: { transform: "scale(1.15)" } }}
              align="center"
              as="button"
              bg="white"
              border="1px"
              borderColor="grey.600"
              borderRadius="full"
              h="10"
              justify="center"
              transitionDuration="normal"
              transitionProperty="common"
              transitionTimingFunction="ease-out"
              w="10"
            >
              {icon}
            </Flex>
          </Link>
        ))}

        <Tooltip backgroundColor="text.primary" color="white" isOpen={shoudShowTooltip} label="Copié !" placement={tooltipPlacement}>
          <Flex
            _hover={{ xl: { transform: "scale(1.15)" } }}
            align="center"
            as="button"
            bg="white"
            border="1px"
            borderColor="grey.600"
            borderRadius="full"
            h="10"
            justify="center"
            onClick={(e) => copyUrl(e)}
            transitionDuration="normal"
            transitionProperty="common"
            transitionTimingFunction="ease-out"
            w="10"
          >
            <CustomIcon name="copyPaste" />
          </Flex>
        </Tooltip>
    </Fragment>
  );
};

export default SocialShare;
