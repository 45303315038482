import { HStack, StackProps, Text, TextProps } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

type FormattedDatesType = {
  firstPublishedAt: string;
  updatedAt?: string | null;
  fontSize?: string | Pick<TextProps, "fontSize">;
  lineHeight?: string | Pick<TextProps, "lineHeight">;
  iconScale?: number;
};

const FormattedDates: FC<FormattedDatesType & StackProps> = ({
  firstPublishedAt,
  updatedAt,
  fontSize = "font-15",
  lineHeight = "base",
  iconScale = 1,
  ...rest
}) => {
  return (
    <HStack spacing="10px" {...rest}>
      <CustomIcon
        name={updatedAt ? 'history' : 'edit'}
        sx={{
          transform: `scale(${iconScale})`,
          path: { fill: 'grey.600' }
        }}
      />

      <Text
        color="grey.600"
        fontSize={fontSize}
        lineHeight={lineHeight}
        whiteSpace="nowrap"
      >
        {updatedAt ? updatedAt : firstPublishedAt}
      </Text>
    </HStack>
  );
};

export default FormattedDates;
